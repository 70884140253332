/* eslint-disable no-restricted-globals */
/**
 * Implements a global loader from bpFxCfd
 * Uses bodymovin library which contains definition
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import * as bodymovin from 'bodymovin'
import './styles.scss'
import ThemedIcon from '../ThemedIcon'

interface IGlobalLoaderProps {
  theme: any
  zIndex?: number
  top?: number
  size?: number
}

const GlobalStyle = createGlobalStyle<{ color: string }>`
	.bg-loader-container svg path {
    	fill: ${(props) => props.color};
	}
`

/**
 * Keep in mind:
 * Default zIndex for GlobalLoader is 100 (to overlay all stuff except theme editor)
 * Should be reduced for chart to 40
 */
const GlobalLoader = ({ theme, zIndex, top, size }: IGlobalLoaderProps) => {
  const [loadingIconName, setLoadingIconName] = useState<string | null>(null)

  useEffect(() => {
    if (location.hostname.includes('xpoption.com')) {
      return setLoadingIconName('xpoption_com')
    } else if (location.hostname.includes('exnie.com')) {
      return setLoadingIconName('exnie_com')
    } else if (location.hostname.includes('equinoxbroker.com')) {
      return setLoadingIconName('equinoxbroker_com')
    } else {
      bodymovin.loadAnimation({
        container: document.getElementById('mainLoader'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('./data.json'),
      })
      bodymovin.play()
    }

    return () => {
      bodymovin.stop()
    }
  }, [])

  const styles = {
    backgroundColor: theme.background,
    zIndex: zIndex || 100,
    top: top || 0,
  }

  return (
    <div className="bg-loader-container" style={styles}>
      {!loadingIconName && <GlobalStyle color={theme.primary} />}
      {!loadingIconName && <div className="main-loader" id="mainLoader" />}
      {loadingIconName && (
        <ThemedIcon
          width={size || 240}
          height={size || 240}
          // fill={theme.primary}
          src={`${process.env.PUBLIC_URL}/static/icons/loading/${loadingIconName}.svg`}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({ theme: state.theme })

export default connect(mapStateToProps)(GlobalLoader)
