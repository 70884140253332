import React from 'react'
import styled from 'styled-components'
import { Background } from './App'
import { t } from 'ttag'

const BannedContainer = styled.section<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.colors.primaryText};
  font-size: 30px;
`

const BannedScreen = ({ colors }: { colors: any }) => {
  return (
    <Background>
      <BannedContainer colors={colors}>
        {t`For regulatory reasons we cannot accept registrants from your place of residence.`}
      </BannedContainer>
    </Background>
  )
}

export default BannedScreen
