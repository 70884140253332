import { ProductType } from '../../reducers/trading'

export const isCfdOptionsVisible = (state: any): boolean =>
  state.registry.data.optionCfdGames?.length > 0

export const isAboveBelowVisible = (state: any): boolean =>
  state.registry.data.aboveBelowGames?.length > 0

export const isTurboVisible = (state: any): boolean =>
  state.registry.data.turboGames?.length > 0

export const isHighLowProductType = (state: any): boolean =>
  state.trading.selectedProductType === ProductType.highLow

export const isCfdOptionsProductType = (state: any): boolean =>
  state.trading.selectedProductType === ProductType.cfdOptions

export const isAboveBelowProductType = (state: any): boolean =>
  state.trading.selectedProductType === ProductType.aboveBelow

export const isTurboProductType = (state: any): boolean =>
  state.trading.selectedProductType === ProductType.turbo

export const getProductTypes = (country: string, registry: any) => {
  const {
    enabledPlatformTypes,
    partnerConfig: { platformTypeDisableCountries, optionsDefaultPlatform },
  } = registry
  const { options, options_cfd } = platformTypeDisableCountries
  let productTypes = [
    ProductType.highLow,
    ProductType.cfdOptions,
    ProductType.aboveBelow,
    ProductType.turbo,
  ]
  if (optionsDefaultPlatform) {
    switch (optionsDefaultPlatform) {
      case 'highLow':
        productTypes = [
          ProductType.highLow,
          ProductType.turbo,
          ProductType.cfdOptions,
          ProductType.aboveBelow,
        ]
        break
      case 'options':
        productTypes = [
          ProductType.cfdOptions,
          ProductType.turbo,
          ProductType.highLow,
          ProductType.aboveBelow,
        ]
        break
      case 'aboveBelow':
        productTypes = [
          ProductType.aboveBelow,
          ProductType.turbo,
          ProductType.highLow,
          ProductType.cfdOptions,
        ]
        break
      case 'turbo':
        productTypes = [
          ProductType.turbo,
          ProductType.highLow,
          ProductType.cfdOptions,
          ProductType.aboveBelow,
        ]
        break
    }
  }

  const isDisabledOptions = enabledPlatformTypes
    ? !enabledPlatformTypes.includes('options')
    : options.split(',').includes(country)

  const isDisabledCfd = enabledPlatformTypes
    ? !enabledPlatformTypes.includes('options_cfd')
    : options_cfd.split(',').includes(country)

  if (isDisabledOptions) {
    productTypes = productTypes.filter((i) => i !== ProductType.highLow)
  }

  if (isDisabledCfd) {
    productTypes = productTypes.filter((i) => i !== ProductType.cfdOptions)
  }

  return productTypes
}
